import { TeacherDetailsFragment } from "../../../../graphql/types"
import { mapMetadata } from "../../../core/mappers/map-metadata"
import { truncate } from "../../../core/utils"
import { TeacherDetails } from "../teacher-types"

export const mapSourceTeacherToView = (
  teacher: TeacherDetailsFragment
): TeacherDetails => {
  const shortBio = teacher!.shortBio?.childMarkdownRemark?.plainText
  const metadata = mapMetadata(
    teacher!.metadata,
    {
      title: teacher!.shortName,
      description: shortBio && truncate(shortBio),
      image: teacher!.image?.fixed?.src
    },
    {
      title: teacher!.pageTitle
    }
  )

  return {
    id: teacher!.id || "",
    firstName: teacher!.firstName || "",
    lastName: teacher!.lastName || "",
    shortName: teacher!.shortName || "",
    image: teacher!.image?.fluid?.src || undefined,
    shortBio:
      teacher!.shortBio?.childMarkdownRemark?.rawMarkdownBody || undefined,
    fullBio:
      teacher!.fullBio?.childMarkdownRemark?.rawMarkdownBody || undefined,
    metadata
  }
}
