import React, { FunctionComponent } from "react"
import { graphql } from "gatsby"
import { TeacherDetailsPageQuery } from "../../../../graphql/types"
import { PageProps } from "../../../types/page"
import { mapSourceTeacherToView } from "../../../features/teachers/mappers/teacher-details-mapper"
import { TeacherDetailsPage } from "../../../features/teachers/components/teacher-details-page/teacher-details-page"
import { Page } from "../../../components/page/page"

export const TeacherTemplate: FunctionComponent<
  PageProps<TeacherDetailsPageQuery>
> = ({ data, ...props }) => {
  const teacher = mapSourceTeacherToView(data!.contentfulTeacher!)
  const teacherData = data?.contentfulTeacher
  const { metadata } = teacherData || {}

  const metadataOverrides = {
    title: metadata?.title || teacher.metadata.title,
    description: metadata?.description || teacher.metadata.description,
    openGraphImage:
      metadata?.image?.fixed?.src || teacher.metadata.openGraphImage
  }

  return (
    <Page
      {...props}
      metadata={{ ...teacher.metadata, ...metadataOverrides }}
      pageOptions={{
        defaultSearchIndex: "newest",
        headerVariant: "cream"
      }}
    >
      <TeacherDetailsPage teacher={teacher} />
    </Page>
  )
}

export default TeacherTemplate

export const query = graphql`
  query TeacherDetailsPage($slug: String) {
    contentfulTeacher(slug: { eq: $slug }) {
      ...TeacherDetails
    }
  }
`
