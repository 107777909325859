import React, { FunctionComponent } from "react"
import { Avatar } from "../../../../components/avatar/avatar"
import { Button } from "../../../../components/button/button"
import styles from "./teacher-header.module.scss"
import { Markdown } from "@components/markdown/markdown"

export interface TeacherHeaderProps {
  shortName: string
  fullBio?: string
  shortBio?: string
  image?: string
  onFollowClick?: () => void
}

export const TeacherHeader: FunctionComponent<TeacherHeaderProps> = ({
  shortName,
  fullBio,
  shortBio,
  image,
  onFollowClick
}) => {
  const name = shortName

  return (
    <header className={styles.header}>
      <div className={styles.content}>
        <div className={styles.info}>
          <Avatar image={image} size="xl" className={styles.avatar} />
          <div className={styles.name}>
            <h1 className={styles.heading}>{name}</h1>
            {!!onFollowClick && (
              <Button
                className={styles.followButton}
                size="sm"
                variant="secondary"
              >
                Follow
              </Button>
            )}
          </div>
        </div>
        <div className={styles.bio}>
          {fullBio ? (
            <Markdown content={fullBio} />
          ) : shortBio ? (
            <Markdown content={shortBio} />
          ) : null}
        </div>
      </div>
    </header>
  )
}
