import React, { FunctionComponent } from "react"
import { TeacherDetails } from "../../teacher-types"
import { TeacherHeader } from "../teacher-header/teacher-header"
import { SearchResults } from "../../../search/components/search-results/search-results"
import { SearchConfig, SearchFilters } from "../../../search/search-types"
import { defaultSearchIndexes } from "../../../../features/search/search-constants"
import styles from "./teacher-details-page.module.scss"

export interface TeacherDetailsProps {
  teacher: TeacherDetails
}

const indexes = defaultSearchIndexes.filter((x) => x.id !== "featured")

const filters: SearchFilters = [
  {
    title: "search-filter-type",
    attribute: "type"
  },
  {
    title: "search-filter-topics",
    attribute: "fields.primaryTopic.fields.name"
  },
  {
    attribute: "fields.primaryScriptureReference.start.book",
    title: "search-filter-scripture"
  }
]

export const TeacherDetailsPage: FunctionComponent<TeacherDetailsProps> = ({
  teacher
}) => {
  const config: SearchConfig = {
    filters: `fields.teachers.fields.shortName:"${teacher.shortName}" AND NOT type:"teacher"`
  }

  return (
    <div className={styles.teacherPage}>
      <TeacherHeader {...teacher} />
      <SearchResults
        className={styles.searchResults}
        config={config}
        indexes={indexes}
        filters={filters}
        variant="list"
      />
    </div>
  )
}
